import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import Anchor from '@components/Anchor';
import Link from '@components/Link';
import LinkContainer from '@components/Link/Link.Container';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Grid from '@components/Grid';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
import CardBorder from '@components/Card/Card.Border';
import CardContent from '@components/Card/Card.Content';
import Wave from '@components/Wave';

import Typeform from '../../components/Typeform';
import Image from '../../components/Image';

import SectionTemplate from '@types';

const ContactIntroduction: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();

    const generalUrl = intl.formatMessage({ id: 'typeform-general-url'});
    const inquiryUrl = intl.formatMessage({ id: 'typeform-contact-url'});
    const callUrl = intl.formatMessage({ id: 'typeform-call-url'});

    return (
        <Section { ...sectionContext }>
            <Wave top waveContext={{ gradientBg: true }} />

            <Transitions.CSS.FadeIn>
                <Wrapper zIndex="1">
                    <Transitions.CSS.FadeInUp as={HeadingsContainer} center>
                        <Headings.h2 center>{intl.formatMessage({ id: 'how-to-contact-title' })}</Headings.h2>
                        { intl.formatMessage({ id: 'how-to-contact-subtitle' }) !== 'how-to-contact-subtitle' ? 
                        <Paragraph center lead>{intl.formatMessage({ id: 'how-to-contact-subtitle' })}</Paragraph>
                        : ``}
                    </Transitions.CSS.FadeInUp>

                    <StyledGrid columns="4">
                        {/*<Link to="/web-development-services">*/}
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="iphone-x.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'contact-call-us' })}</CardHeader>
                                    { intl.formatMessage({ id: 'contact-call-us-description' }) !== 'contact-call-us-description' ? 
                                    <CardParagraph>{intl.formatMessage({ id: 'contact-call-us-description' })}</CardParagraph>
                                    : ''}
                                    <StyledAnchor href="tel:+36307415709" className="button button--dark" style={{marginTop: '20px', padding: '10px 18px'}}>{intl.formatMessage({ id: 'contact-call-us-cta' })}</StyledAnchor>
                                </StyledCardContent>
                            </CardDefault>
                        {/*</Link>*/}

                        {/*<Link to="/web-development-services">*/}
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="meeting-table.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'contact-general' })}</CardHeader>
                                    { intl.formatMessage({ id: 'contact-general-description' }) !== 'contact-general-description' ? 
                                    <CardParagraph>{intl.formatMessage({ id: 'contact-general-description' })}</CardParagraph>
                                    : ''}
                                    <Typeform url={generalUrl} styles={{marginTop: '20px', padding: '10px 18px'}} style={{ display: 'none' }}>{intl.formatMessage({ id: 'contact-general-cta' })}</Typeform>
                                </StyledCardContent>
                            </CardDefault>
                        {/*</Link>*/}

                        {/*<Link to="/web-development-services">*/}
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="iphone-white.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'contact-call' })}</CardHeader>
                                    { intl.formatMessage({ id: 'contact-call-description' }) !== 'contact-call-description' ? 
                                    <CardParagraph>{intl.formatMessage({ id: 'contact-call-description' })}</CardParagraph>
                                    : ''}
                                    <Typeform url={callUrl} styles={{marginTop: '20px', padding: '10px 18px'}} style={{ display: 'none' }}>{intl.formatMessage({ id: 'contact-call-cta' })}</Typeform>
                                </StyledCardContent>
                            </CardDefault>
                        {/*</Link>*/}

                        {/*<Link to="/web-development-services">*/}
                            <CardDefault hover animateImage animateBorder>
                                <CardImageContainer>
                                    <Image imgName="diary-notebook.jpg" />
                                    <CardBorder gradient className="card-border" />
                                </CardImageContainer>
                                <StyledCardContent>
                                    <CardHeader>{intl.formatMessage({ id: 'contact-inquiry' })}</CardHeader>
                                    { intl.formatMessage({ id: 'contact-inquiry-description' }) !== 'contact-inquiry-description' ? 
                                    <CardParagraph>{intl.formatMessage({ id: 'contact-inquiry-description' })}</CardParagraph>
                                    : ''}
                                    <Typeform url={inquiryUrl} styles={{marginTop: '20px', padding: '10px 18px'}} style={{ display: 'none' }}>{intl.formatMessage({ id: 'contact-inquiry-cta' })}</Typeform>
                                    {/*<Link to="mailto:hello@dlmrconsulting.com" className="button button--dark">{intl.formatMessage({ id: 'contact-email-cta' })}</Link>*/}
                                </StyledCardContent>
                            </CardDefault>
                        {/*</Link>*/}
                    </StyledGrid>
                    <StyledHeadingsContainer center>
                        <Headings.h3>{intl.formatMessage({ id: 'contact-other-title'})}</Headings.h3>
                        <Paragraph lead center>
                            <FormattedMessage id="contact-other-description" values={{
                                contactEmailLink: (
                                    <Anchor href={`mailto:hello@dlmrconsulting.com`}><FormattedMessage id="contact-other-link" /> </Anchor>
                                )
                            }} />
                        </Paragraph>
                        <LinkContainer>
                            <Anchor href="mailto:hello@dlmrconsulting.com" className="button button--dark" style={{ color: 'white' }}>{intl.formatMessage({ id: 'email-us' })}</Anchor>
                        </LinkContainer>
                    </StyledHeadingsContainer>
                </Wrapper>
            </Transitions.CSS.FadeIn>

        </Section>
    );
};


const StyledAnchor = styled(Anchor)`
    color: white;

    ${mediaqueries.from_laptop`
        display: table;
    `}
`;


const CardHeader = styled(Headings.h4)`
    margin-bottom: 0px;
    margin-bottom: 10px;
    // text-align: center;
    font-weight: bold;
`;

const CardParagraph = styled(Paragraph)`
    margin-bottom: 0px !important;
    font-size: 16px;
    max-width: unset;
`;

const StyledGrid = styled(Grid)`
    margin-top: 50px;
`;

const StyledCardContent = styled(CardContent)`
    padding: 7% 5%;

    & button {
        ${mediaqueries.from_laptop`
            display: block;
        `}
    }
`;

const StyledHeadingsContainer = styled(HeadingsContainer)`
    margin-top: 70px;

    & a:first-of-type {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
`;

export default ContactIntroduction;