import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

import SVGBackground from '@misc/hero-background.svg';

const HeroBackground = styled(SVGBackground)`
    position: absolute;
    // width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    min-width: 700px !important;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    // object-fit: cover;
    // background-position: center center;
    // background-size: contain;
`;

export default HeroBackground;