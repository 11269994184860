import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import LinkContainer from '@components/Link/Link.Container';
import HeroBackground from '@components/Hero/Hero.Background';

import TranslatedSectionTemplate from '@types';

/**
 * This is a template for all Subpage Heroes
*/

const SubHero: TranslatedSectionTemplate = ({ sectionContext, translations }) => {
    const intl = useIntl();
    
    return (
        <Transitions.CSS.FadeIn>
            <Section hero gradientBg { ...sectionContext }>
                <HeroBackground />

                <Wrapper zIndex="1">
                    <HeroWrapper narrow center>
                        <Transitions.CSS.FadeInUp delay="0.1">
                            <Heading className="page-h2">{ translations.title.text }</Heading>
                        </Transitions.CSS.FadeInUp>
                        { translations.subtitle && 
                        <Transitions.CSS.FadeInUp delay="0.2">
                            <Paragraph subtitle>{ translations.subtitle.text }</Paragraph>
                        </Transitions.CSS.FadeInUp>
                        }
                        { (translations.Link1 || translations.Link2) && 
                        <Transitions.CSS.FadeInUp delay="0.3">
                            <LinkContainer>
                                { translations.Link1 &&
                                <Link to={ translations.Link1.link } className="button button--dark" data-scroll>{ translations.Link1.text }</Link> }
                                { translations.Link2 &&
                                <Link to={ translations.Link2.link } className="button button--white">{ translations.Link2.text }</Link> }
                            </LinkContainer>
                        </Transitions.CSS.FadeInUp>
                        }
                    </HeroWrapper>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>
    );
};

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

export default SubHero;