import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import ContactHero from '@sections/subpage/Subpage.Hero';
import ContactIntroduction from '@sections/contact/Contact.Introduction';
import ContactCTA from '@sections/CTA/CTA.Main';

const ContactPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = true;

    const HeroTranslations = {
        title: {
            id: 'contact',
            text: intl.formatMessage({ id: 'contact' })
        },
        subtitle: {
            id: 'contact-subtitle',
            text: intl.formatMessage({ id: 'contact-subtitle' })
        } 
    }

    return (
    <Layout id="contact" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'contact-meta-title' })}
            description={intl.formatMessage({ id: 'contact-meta-description'})}
            slug='/contact/'
        />
        <ContactHero translations={HeroTranslations} />
        <ContactIntroduction sectionContext={{ small: true }} />
        <ContactCTA sectionContext={{ small: true, gradientBg: false, noPaddingTop: true }} />
    </Layout>
    )
}

export default ContactPage;
